import React, { useContext, useEffect, useState } from 'react';

import LegalPage        from '../components/LegalPage/LegalPage';
import { StoreContext } from '../context/Store';
import PriceTag         from '../components/PriceTag';

const VersandUndZahlungPage = () => {
  const [shippingMethods, setShippingMethods] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const { settings } = useContext(StoreContext);
  const { data, loading } = settings;

  const buildMethods = async (array, id, excludeId = 0) => {
    const newArray = [];
    await array.forEach((method, index) => {
      method = method[id];
      if (method.id !== excludeId) {
        newArray.push(method.display_name);
        if (array.length > index + 1) {
          if (array.length === index + 2) {
            newArray.push('oder')
          } else {
            newArray.push(',');
          }
        }
      }
    })
    return newArray.join(' ');
  }

  const setMethods = async () => {
    const shipping = await buildMethods(data.shipping_methods, 'shipping_id');
    const payment = await buildMethods(data.payment_methods, 'payment_id')
    setShippingMethods(shipping);
    setPaymentMethods(payment);
  }

  useEffect(() => {
    if (data) {
      setMethods()
    }
    //eslint-disable-next-line
  }, [data])

  return (
    <LegalPage type='versand-und-zahlung'>

      {loading && <p>Laden</p>}

      {data && <>
        <p>Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und sonstige
          Preisbestandteile.</p>

        <p>Lieferungen sind nur an Lieferadressen
          in {data.shipping_countries.map(({ country_id }) => country_id.display_name)} möglich. Die
          Lieferung erfolgt
          mit {shippingMethods ? shippingMethods : null}.
        </p>

        <p>Der Versand innerhalb Deutschlands kostet pauschal <PriceTag priceNetto={data.shipping_cost}
                                                                        showMwst={false}/>.
        </p>

        <p>Sie können wahlweise
          per {paymentMethods ? paymentMethods : null} bezahlen.</p>
      </>}

    </LegalPage>
  )
};

export default VersandUndZahlungPage;